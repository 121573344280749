@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
html {
  overflow-x: hidden;
  font-size: 10px; }
  @media screen and (min-width: 900px) {
    html {
      font-size: 11px; } }
  @media screen and (min-width: 1000px) {
    html {
      font-size: 12px; } }
  @media screen and (min-width: 1200px) {
    html {
      font-size: 14px; } }
  @media screen and (min-width: 1400px) {
    html {
      font-size: 15px; } }
  @media screen and (min-width: 1600px) {
    html {
      font-size: 16px; } }

.ant-table {
  font-size: 0.9em; }

.bg-light, .tabBtn {
  background: #fff !important; }

.bg-secondary {
  background-color: #f3f7fa !important; }

.ant-drawer {
  position: fixed !important; }

.textnoWrap {
  white-space: nowrap; }

.btn-success {
  background: #6fa85a;
  border-color: #6fa85a;
  color: #fff; }

.ant-btn:not(.ant-btn-primary):not(.ant-btn-background-ghost):hover {
  color: #fff;
  background-color: #193663;
  border-color: #193663; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #e05b0c;
  border-color: #e05b0c; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif; }

@media (max-width: 1400px) {
  .h1,
  h1 {
    font-size: 1.9rem; }
  .h2,
  h2 {
    font-size: 1.6rem; }
  .h3,
  h3 {
    font-size: 1.4rem; }
  .h4,
  h4 {
    font-size: 1.1rem; }
  .h5,
  h5,
  .ant-tooltip {
    font-size: 1rem; }
  .h6,
  h6 {
    font-size: 0.9rem; } }

.flexCenter, .reqPopupMain .circleCustom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center; }

.dropdown-item,
a {
  color: #16181b; }

.topheaderdropdown .dropdown-item,
li.nav-item .useName {
  cursor: pointer; }

.dropdown-item:hover,
.dropdown-item:active,
.dropdown-item:focus {
  color: #16181b;
  background-color: #e4e4e4; }

.underline {
  text-decoration: underline; }

.primeryText {
  font-size: 14px;
  font-weight: 600; }

.btn-secondary {
  background: #8c8c8c;
  border-color: #8c8c8c;
  color: #fff;
  padding: 5px 15px;
  border: 0;
  height: 38px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }
  .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
    background: #666666;
    border-color: #666666;
    outline: none;
    box-shadow: none !important;
    color: #fff; }

.show > .btn-primary.dropdown-toggle,
.ant-btn-primary,
.ant-btn-primary,
.ant-btn:not(.ant-btn-background-ghost),
.ant-btn-primary,
.button-primary,
.ant-btn-primary:not(.ant-btn-sm),
.btn-primary,
a.btn-primary,
.button {
  background: #f4762b;
  border-color: #f4762b;
  color: #fff; }
  .show > .btn-primary.dropdown-toggle:hover, .show > .btn-primary.dropdown-toggle:active, .show > .btn-primary.dropdown-toggle:focus,
  .ant-btn-primary:hover,
  .ant-btn-primary:active,
  .ant-btn-primary:focus,
  .ant-btn-primary:hover,
  .ant-btn-primary:active,
  .ant-btn-primary:focus,
  .ant-btn:not(.ant-btn-background-ghost):hover,
  .ant-btn:not(.ant-btn-background-ghost):active,
  .ant-btn:not(.ant-btn-background-ghost):focus,
  .ant-btn-primary:hover,
  .ant-btn-primary:active,
  .ant-btn-primary:focus,
  .button-primary:hover,
  .button-primary:active,
  .button-primary:focus,
  .ant-btn-primary:not(.ant-btn-sm):hover,
  .ant-btn-primary:not(.ant-btn-sm):active,
  .ant-btn-primary:not(.ant-btn-sm):focus,
  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:focus,
  a.btn-primary:hover,
  a.btn-primary:active,
  a.btn-primary:focus,
  .button:hover,
  .button:active,
  .button:focus {
    background-color: #e05b0c;
    border-color: #e05b0c;
    text-decoration: none;
    outline: none;
    box-shadow: none !important; }

.button-primary,
.ant-btn-primary:not(.ant-btn-sm),
.btn-primary,
a.btn-primary,
.button {
  padding: 5px 15px;
  border: 0;
  height: 38px;
  min-width: 100px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.popup-content {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }
  .popup-content .modelPopupCommon {
    position: relative;
    background: #fff; }

.gradientBtn,
a.gradientBtn,
input.gradientBtn,
button.gradientBtn,
.btn-primary.gradientBtn {
  background: linear-gradient(0deg, #f48665 0%, #fea23f 100%); }
  .gradientBtn:hover, .gradientBtn:active, .gradientBtn:focus,
  a.gradientBtn:hover,
  a.gradientBtn:active,
  a.gradientBtn:focus,
  input.gradientBtn:hover,
  input.gradientBtn:active,
  input.gradientBtn:focus,
  button.gradientBtn:hover,
  button.gradientBtn:active,
  button.gradientBtn:focus,
  .btn-primary.gradientBtn:hover,
  .btn-primary.gradientBtn:active,
  .btn-primary.gradientBtn:focus {
    background: linear-gradient(0deg, #f48665 0%, #fea23f 100%); }

.text-underline {
  text-decoration: underline; }

.gradientBtn {
  max-width: 250px;
  width: 100%;
  min-height: 70px;
  display: flex !important;
  align-items: center;
  justify-content: center; }

input,
select,
.ant-select-selection,
.form-control,
.ant-input {
  font-size: 0.9rem;
  line-height: 1;
  height: 40px;
  padding: 5px 10px;
  outline: 0;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  color: #1a3665;
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }
  input:focus,
  select:focus,
  .ant-select-selection:focus,
  .form-control:focus,
  .ant-input:focus {
    border-color: #8a8a8a;
    box-shadow: 0 0 2px 0 #cacaca;
    -webkit-box-shadow: 0 0 2px 0 #cacaca;
    -moz-box-shadow: 0 0 2px 0 #cacaca; }
  input.has-error,
  select.has-error,
  .ant-select-selection.has-error,
  .form-control.has-error,
  .ant-input.has-error {
    border-color: #f00; }

textarea {
  font-size: 0.9rem;
  line-height: 1;
  padding: 5px 10px;
  outline: 0;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  color: #1a3665;
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }
  textarea:focus {
    border-color: #8a8a8a;
    box-shadow: 0 0 2px 0 #cacaca;
    -webkit-box-shadow: 0 0 2px 0 #cacaca;
    -moz-box-shadow: 0 0 2px 0 #cacaca; }
  textarea.has-error {
    border-color: #f00; }

.equal-size-button {
  width: 100px; }

.cursor-pointer,
.ant-table-row {
  cursor: pointer; }

.ant-select-selection {
  border-color: #8a8a8a; }

.btn-lg {
  font-size: 16px; }

.subTitle {
  color: #8c8c8c;
  font-size: 0.9rem; }

img {
  max-width: 100%; }

.colorWhite {
  color: #fff; }

.color-primary {
  color: #f4762b; }

.color-secondary {
  color: #1a3665 !important; }

svg.color-secondary {
  fill: #1a3665 !important; }

.text-blue {
  color: #539ae0; }

svg.icon {
  height: 22px;
  width: 22px;
  fill: #535356; }
  svg.icon:not(.delete):hover {
    fill: #f4762b; }
  svg.icon.delete {
    fill: #e63634;
    color: #e63634; }

svg.iconMedium {
  height: 35px;
  width: 35px; }

svg.iconMain, .progressBarWrapper .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  height: 45px;
  width: 45px; }

@media (max-width: 1400px) {
  svg.icon {
    height: 16px;
    width: 16px; }
  svg.iconMedium {
    height: 25px;
    width: 25px; }
  svg.iconMain, .progressBarWrapper .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
    height: 35px;
    width: 35px; } }

.activeIcon .icon {
  fill: #f4762b; }

.max-300 {
  max-width: 320px; }

.sectionHeading {
  color: #1a3665;
  font-size: 20px; }

.color-red {
  color: #e63634 !important;
  font-weight: 600; }

.fontNunito {
  font-family: "Nunito", sans-serif; }

.projectLogo {
  height: 42px; }

body {
  font-family: "Nunito", sans-serif;
  color: #535356;
  background: #f3f7fa;
  height: auto;
  font-size: 1em; }
  @media (max-width: 1400px) {
    body {
      font-size: 12px; } }

.sidebar {
  white-space: nowrap;
  position: absolute;
  z-index: 9;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .sidebar + .col {
    width: calc(100% - 320px); }
  .sidebar .toggleMenu {
    cursor: pointer;
    position: absolute;
    z-index: 9;
    right: -28px;
    background: #fff;
    box-shadow: 3px 2px 2px -1px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 3px 2px 2px -1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 3px 2px 2px -1px rgba(0, 0, 0, 0.2); }
    .sidebar .toggleMenu > .icon {
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      transform: rotate(180deg);
      width: 20px;
      height: 20px; }
  .sidebar .nav-link {
    min-height: 65px; }
  .sidebar.sidebarInvisible {
    max-width: 129px;
    flex: 1 1 130px; }
    .sidebar.sidebarInvisible + .col {
      width: calc(100% - 150px); }
    .sidebar.sidebarInvisible .navTitle {
      display: none; }
    .sidebar.sidebarInvisible .toggleMenu > .icon {
      transform: rotate(0); }
  @media (max-width: 1400px) {
    .sidebar {
      max-width: 250px; }
      .sidebar.sidebarInvisible {
        max-width: 105px;
        flex: 1 1 105px; } }

.container-fluid {
  max-width: 2300px; }

.container {
  max-width: 1060px; }

.container-fluid.fullHeight {
  min-height: calc(100vh - 85px); }

.userIconWrap {
  display: inline-block;
  height: 35px;
  width: 35px;
  background: #f1f1f1;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%; }
  .userIconWrap > img {
    height: 100%; }

.homeHeader {
  max-width: 70px;
  line-height: 80px;
  cursor: pointer; }
  .homeHeader > .iconMain {
    height: 30px;
    width: 30px; }

.homeTypeList .activeHomeTab {
  color: #1890ff;
  border-color: #1890ff; }

.iconDesign {
  padding: 5px;
  background: #f4762b;
  height: 45px;
  width: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%; }

.iconSmall {
  height: 25px;
  width: 25px; }

.iconOrange {
  background: #fde7da; }
  .iconOrange svg,
  .iconOrange svg:hover {
    fill: #f4762b; }

.iconPurple {
  background: #e9e2ff; }
  .iconPurple svg,
  .iconPurple svg:hover {
    fill: #7b53ff; }

.iconCyan {
  background: #daebf1; }
  .iconCyan svg,
  .iconCyan svg:hover {
    fill: #7dbace; }

.iconGreen {
  background: #cbf1de; }
  .iconGreen svg,
  .iconGreen svg:hover {
    fill: #3ecc83; }

.iconBlue {
  background: #c6d3ef; }
  .iconBlue svg,
  .iconBlue svg:hover {
    fill: #3a68c9; }

.iconYellow {
  background: #fff2d2; }
  .iconYellow svg,
  .iconYellow svg:hover {
    fill: #fbb100; }

.font-medium {
  font-weight: 600; }

label,
.label {
  font-size: 0.9rem;
  display: block;
  font-weight: 600; }

.inputCheck {
  display: inline-block; }
  .inputCheck > input {
    position: absolute;
    visibility: hidden; }
    .inputCheck > input + label:before {
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
      border: 1px solid #747474;
      vertical-align: middle;
      margin: -1px 10px;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      position: absolute;
      top: 4px;
      left: 0; }
    .inputCheck > input + label:after {
      content: "";
      height: 8px;
      width: 8px;
      background: #747474;
      display: inline-block;
      position: absolute;
      left: 14px;
      top: 7px;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      opacity: 0; }
    .inputCheck > input:checked + label:before {
      border-color: #018466; }
    .inputCheck > input:checked + label:after {
      opacity: 1;
      background: #018466; }
    .inputCheck > input[type="radio"] + label:before {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
    .inputCheck > input[type="radio"]:checked + label:after {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%; }
    .inputCheck > input[type="checkbox"]:checked + label:before {
      background: #018466;
      border-color: #018466; }
    .inputCheck > input[type="checkbox"]:checked + label:after {
      height: 7px;
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: rotate(-45deg); }
  .inputCheck.svgCheck > label:before, .inputCheck.svgCheck > label:after {
    display: none; }
  .inputCheck > label {
    position: relative;
    padding-left: 30px; }

.ant-table-column-title {
  font-size: 1.1em;
  font-weight: 600;
  color: #535356;
  white-space: nowrap; }

.loginPage {
  background: #193663; }
  .loginPage .login-form-wrap {
    min-height: 530px;
    max-width: 495px;
    background-size: cover;
    padding: 20px;
    margin: 20px;
    color: #fff; }
    .loginPage .login-form-wrap label {
      color: #fff; }
    @media (max-width: 567px) {
      .loginPage .login-form-wrap {
        min-height: auto; } }
  .loginPage .login-form-inner-wrap {
    max-width: 360px; }
    .loginPage .login-form-inner-wrap > h3 {
      font-size: 24px;
      text-align: center;
      line-height: 1.6;
      font-weight: 700; }
    .loginPage .login-form-inner-wrap .login-sec .form-group > label {
      margin-bottom: 5px; }
    .loginPage .login-form-inner-wrap .login-sec .form-group input {
      width: 100%;
      background: #f0f0f0;
      height: 38px;
      padding: 5px 12px;
      outline: none; }

.privateHeaderMain {
  background: #193663; }
  .privateHeaderMain .navbar {
    min-height: 80px; }
    .privateHeaderMain .navbar .logoWrap,
    .privateHeaderMain .navbar #navbarSupportedContent {
      max-width: 300px;
      width: 100%; }
    .privateHeaderMain .navbar .logoWrap {
      max-width: 350px; }
    .privateHeaderMain .navbar #navbarSearch {
      max-width: 800px;
      width: 100%; }
      .privateHeaderMain .navbar #navbarSearch .searchBar {
        position: relative; }
        .privateHeaderMain .navbar #navbarSearch .searchBar .search-input-fild {
          width: 100%;
          height: 38px;
          background: #2d457a;
          color: #fff;
          border-color: #2d457a; }
          .privateHeaderMain .navbar #navbarSearch .searchBar .search-input-fild:focus {
            border-color: #2d457a; }
          .privateHeaderMain .navbar #navbarSearch .searchBar .search-input-fild::-webkit-input-placeholder {
            color: #fff; }
        .privateHeaderMain .navbar #navbarSearch .searchBar .icon {
          fill: #fff; }
        .privateHeaderMain .navbar #navbarSearch .searchBar button {
          position: absolute;
          right: 0;
          background: transparent;
          border: 0;
          outline: 0;
          top: 0;
          bottom: 0; }
        .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult {
          top: 100%;
          left: 0;
          right: 0;
          background: #fff;
          list-style: none;
          padding: 0;
          z-index: 9;
          max-height: 300px;
          overflow-y: auto;
          box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.2);
          -webkit-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.2); }
          .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult > li {
            padding: 5px 10px;
            min-height: 40px; }
            .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult > li:last-child {
              border: 0; }
            .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult > li.noDataFound {
              min-height: 100px; }
            .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult > li .resultId {
              width: 80px; }
            .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult > li .resultDescription {
              width: calc(100% - 120px); }
            .privateHeaderMain .navbar #navbarSearch .searchBar .searchResult > li .resultType {
              width: 40px;
              text-align: right; }
        .privateHeaderMain .navbar #navbarSearch .searchBar .search-input-fild:focus ~ .searchResult,
        .privateHeaderMain .navbar #navbarSearch .searchBar #headerSearch:focus ~ .searchResult {
          visibility: visible; }
    .privateHeaderMain .navbar .navbar-brand {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      min-height: 80px; }
      .privateHeaderMain .navbar .navbar-brand > h2 {
        color: #fff; }
        .privateHeaderMain .navbar .navbar-brand > h2 small {
          font-size: 0.9rem;
          color: #f4762b; }
    .privateHeaderMain .navbar .userInfo .dropdown-toggle:after {
      content: "";
      border: 0; }
    .privateHeaderMain .navbar .userInfo .dropdown-toggle .useName {
      text-transform: capitalize;
      line-height: 1;
      height: 15px; }
      .privateHeaderMain .navbar .userInfo .dropdown-toggle .useName .icon {
        width: 20px;
        height: 22px; }
    .privateHeaderMain .navbar .userInfo .dropdown-menu {
      border: 0;
      box-shadow: 0px 6px 8px -1px #b7b7b7;
      -webkit-box-shadow: 0px 6px 8px -1px #b7b7b7;
      -moz-box-shadow: 0px 6px 8px -1px #b7b7b7; }
    .privateHeaderMain .navbar .navbar-nav .nav-item > a.nav-link {
      color: #fff; }
      .privateHeaderMain .navbar .navbar-nav .nav-item > a.nav-link > svg {
        fill: #fff; }
    @media (max-width: 992px) {
      .privateHeaderMain .navbar .navbar-collapse {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        margin: 0 -15px;
        background: #fff;
        z-index: 9; }
        .privateHeaderMain .navbar .navbar-collapse .nav-item {
          width: 100%;
          margin: 5px 0; }
          .privateHeaderMain .navbar .navbar-collapse .nav-item > a {
            padding: 10px 25px; }
            .privateHeaderMain .navbar .navbar-collapse .nav-item > a:hover {
              background: #f2f2f2; } }

.downloadTempleteBtn {
  color: #3a68c9;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer; }

.drap-drop-sec {
  min-height: 100px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  border: 3px dashed #eeeeee;
  color: #bdbdbd;
  padding: 0;
  position: relative; }
  .drap-drop-sec section,
  .drap-drop-sec .dropzone {
    width: 100%;
    min-height: 90px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 90px; }
    .drap-drop-sec section + aside,
    .drap-drop-sec .dropzone + aside {
      position: absolute;
      left: 0;
      right: 0;
      top: 0; }
  .drap-drop-sec aside ul {
    text-align: center;
    list-style: none;
    padding: 0;
    color: #333; }

.analysisWrap > .heading {
  color: #1a3665; }

.analysisWrap .downloadTemplate {
  text-decoration: underline;
  color: #3a68c9;
  font-weight: 600; }

.ant-notification {
  left: 0;
  margin: 100px auto 0; }

.ant-spin {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10000; }

.boxWithShado, .sidebar-wrapper, .settingPopContent .ant-collapse .ant-collapse-item, .dashboardTopBarGraphs .headerAccordion, .ant-message-notice-content .ant-message-custom-content, .ant-tag, .ObjectActionCollapse .ant-collapse .ant-collapse-item {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }

.sidebar-wrapper {
  background: #fff;
  min-height: calc(100vh - 80px);
  height: calc(100vh - 80px);
  overflow-y: auto;
  transition: all ease-in-out 0s !important;
  -webkit-transition: all ease-in-out 0s;
  -moz-transition: all ease-in-out 0s;
  -o-transition: all ease-in-out 0s;
  transition: all ease-in-out 0s; }
  .sidebar-wrapper .nav-item {
    border-left: 3px solid transparent;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; }
    .sidebar-wrapper .nav-item .nav-link {
      font-size: 0.9rem;
      color: #8c8c8c; }
      .sidebar-wrapper .nav-item .nav-link .iconMain {
        flex: none; }
      .sidebar-wrapper .nav-item .nav-link .navTitle {
        flex: 1; }
        .sidebar-wrapper .nav-item .nav-link .navTitle > h5 {
          line-height: 1; }
      .sidebar-wrapper .nav-item .nav-link .iconOrange + .navTitle > h5 {
        color: #f4762b; }
      .sidebar-wrapper .nav-item .nav-link .iconCyan + .navTitle > h5 {
        color: #7dbace; }
      .sidebar-wrapper .nav-item .nav-link .iconPurple + .navTitle > h5 {
        color: #7b53ff; }
    .sidebar-wrapper .nav-item:hover, .sidebar-wrapper .nav-item.active {
      background: #eef2fa;
      border-left: 3px solid #1a3665; }

.sidebar:not(.sidebarInvisible) .sidebar-wrapper {
  box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 15px 0px rgba(0, 0, 0, 0.2); }

.ant-affix {
  min-width: 130px;
  height: 100% !important;
  width: auto !important; }
  .ant-affix .sidebar-wrapper {
    height: 100vh;
    overflow-y: auto; }

.isVisible .ant-affix {
  width: 100% !important;
  max-width: 320px;
  min-width: auto; }

.dashboardTopBar {
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
  .dashboardTopBar .analysisInfo > p {
    color: #8c8c8c; }
  .dashboardTopBar .analysisInfo > h5 {
    color: #1a3665;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 175px; }
    @media (max-width: 1560px) {
      .dashboardTopBar .analysisInfo > h5 {
        width: 130px; } }
    @media (max-width: 1300px) {
      .dashboardTopBar .analysisInfo > h5 {
        width: 100px; } }
  .dashboardTopBar .topBarActions {
    margin: 0 auto;
    max-width: 280px; }

.showData {
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }
  .showData + .row a {
    color: #1a3665; }
  .showData .eastematTime label {
    line-height: 1; }

.reqPopupMain .lebleReqid > span {
  color: #3a68c9; }

.reqPopupMain .ant-modal-close-x {
  width: 36px;
  height: 36px;
  line-height: 36px; }

.reqPopupMain .circleCustom {
  height: 70px;
  width: 70px;
  color: #fff;
  background: #f4762b;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25); }
  .reqPopupMain .circleCustom.redCircle {
    background: red; }
  .reqPopupMain .circleCustom.circleCustom_blue {
    background: #1a3665; }

.reqPopupMain .totalIQ {
  max-width: 120px;
  min-height: 100px;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed #e0dede;
  -webkit-border: 1px dashed #e0dede;
  -moz-border: 1px dashed #e0dede; }

.reqPopupMain .popup-content {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.reqPopupMain .actionObj {
  margin: 20px 0; }
  .reqPopupMain .actionObj label {
    margin: 0;
    display: inline-flex;
    align-items: center; }
    .reqPopupMain .actionObj label span {
      padding: 4px 15px;
      background: #000;
      display: inline-block;
      margin-right: 5px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px; }
      .reqPopupMain .actionObj label span.definAction {
        background: #40d1ba; }
      .reqPopupMain .actionObj label span.definObject {
        background: #8e9ab2; }
      .reqPopupMain .actionObj label span.definOtherAction {
        background: #67cdf3; }

.reqPopupMain .requirementColumn {
  line-height: 2; }
  .reqPopupMain .requirementColumn .reqContent {
    min-height: 200px;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px; }
    .reqPopupMain .requirementColumn .reqContent .actionEl {
      background: #40d1ba;
      padding: 0px 3px;
      line-height: 1.5;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px; }
    .reqPopupMain .requirementColumn .reqContent .objectEl {
      background: #8e9ab2;
      padding: 0px 3px;
      line-height: 1.5;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px; }
    .reqPopupMain .requirementColumn .reqContent .otherActionEl {
      background: #67cdf3;
      padding: 0px 3px;
      line-height: 1.5;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px; }
  .reqPopupMain .requirementColumn textarea.form-control {
    line-height: 1.5; }

.reqPopupMain .tabContents > div {
  display: none; }
  .reqPopupMain .tabContents > div.active {
    display: block; }

.ant-tabs-nav .ant-tabs-tab {
  margin-right: 0;
  padding: 12px; }
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon {
    fill: #f4762b; }

.closePopup {
  font-weight: 300;
  font-size: 30px;
  position: absolute;
  z-index: 9;
  line-height: 1;
  right: 7px;
  top: 0px; }

.ant-tag {
  display: inline-flex;
  align-items: center; }

.ant-tag-hidden {
  display: none; }

.listOfUncDatePop .numBox {
  display: inline-block;
  height: 16px;
  width: 16px;
  text-align: center;
  line-height: 15px;
  font-size: 12px; }

.listOfUncDatePop label {
  width: 90%; }

.listOfUncDatePop .ant-checkbox-wrapper {
  padding-right: 8px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between; }
  .listOfUncDatePop .ant-checkbox-wrapper .ant-checkbox {
    margin-top: 1px; }
  .listOfUncDatePop .ant-checkbox-wrapper .ant-checkbox-checked::after {
    height: auto; }

.deletePopup .deletePopup_buttons {
  max-width: 350px; }

@-moz-document url-prefix() {
  .tableCustome .ant-table-body colgroup {
    display: none; } }

.tableCustome .requirmentID,
.tableCustome .tableID {
  white-space: nowrap;
  color: #7085d2; }

.tableCustome .ant-table-row-level-1 .requirmentID,
.tableCustome .ant-table-row-level-1 .tableID {
  color: #f4762b; }

.tableCustome table {
  font-weight: 600; }
  .tableCustome table strong {
    font-weight: 700; }
  .tableCustome table .ant-table-thead > tr > th {
    background: transparent;
    border: 0;
    padding: 0 16px; }
    .tableCustome table .ant-table-thead > tr > th .ant-table-header-column {
      line-height: 2.5; }
      .tableCustome table .ant-table-thead > tr > th .ant-table-header-column > div {
        display: table; }
  .tableCustome table .ant-table-tbody .ant-table-row > td {
    background: #fff;
    padding: 10px 16px;
    border: 0; }
    .tableCustome table .ant-table-tbody .ant-table-row > td span.text-primary {
      vertical-align: bottom;
      color: #6a84f7 !important; }
    .tableCustome table .ant-table-tbody .ant-table-row > td .ant-table-row-expand-icon:after {
      content: "";
      height: 20px;
      width: 20px;
      display: inline-block; }
    .tableCustome table .ant-table-tbody .ant-table-row > td .ant-table-row-expand-icon.ant-table-row-collapsed:after {
      background: url("../img/arrowRight.svg") no-repeat center;
      background-size: 12px;
      vertical-align: middle;
      -webkit-transition: all ease-in 0.3s;
      -moz-transition: all ease-in 0.3s;
      -o-transition: all ease-in 0.3s;
      transition: all ease-in 0.3s; }
    .tableCustome table .ant-table-tbody .ant-table-row > td .ant-table-row-expand-icon.ant-table-row-expanded:after {
      background: url("../img/arrowRight.svg") no-repeat center;
      background-size: 12px;
      vertical-align: middle;
      -webkit-transition: all ease-in 0.3s;
      -moz-transition: all ease-in 0.3s;
      -o-transition: all ease-in 0.3s;
      transition: all ease-in 0.3s;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      transform: rotate(90deg); }
  .tableCustome table .ant-table-tbody .ant-table-row.ant-table-row-level-1 > td {
    background: #f1f1f1; }
    .tableCustome table .ant-table-tbody .ant-table-row.ant-table-row-level-1 > td.requirmentID {
      text-align: right; }
    .tableCustome table .ant-table-tbody .ant-table-row.ant-table-row-level-1 > td .ant-table-row-expand-icon {
      display: none; }
  .tableCustome table .ant-table-tbody .ant-table-row.ant-table-row-level-0 > td {
    border-top: solid 10px #f3f7fa; }

.tableCustome .ant-pagination > li,
.tableCustome .ant-pagination > li a {
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .tableCustome .ant-pagination > li.ant-pagination-prev a,
  .tableCustome .ant-pagination > li.ant-pagination-next a,
  .tableCustome .ant-pagination > li a.ant-pagination-prev a,
  .tableCustome .ant-pagination > li a.ant-pagination-next a {
    width: 32px; }

.tableCustome.removeChild .ant-table-row-collapsed {
  display: none; }

.tableCustome.multiplierTable .ant-table-tbody .ant-table-row > td {
  border: 0 !important; }

.tabelNewVariant table {
  font-weight: 600; }
  .tabelNewVariant table strong {
    font-weight: 700; }
  .tabelNewVariant table .ant-table-thead > tr > th {
    background: transparent;
    border: 0; }
    .tabelNewVariant table .ant-table-thead > tr > th .ant-table-header-column > div {
      display: table; }
  .tabelNewVariant table .ant-table-tbody .ant-table-row > td {
    background: #fff;
    border: 0; }

.tabelNewVariant.rowDesignTable .ant-table-tbody > tr > td {
  font-weight: 400; }

.tabelNewVariant.rowDesignTable .ant-table-tbody > tr:nth-child(3) > td,
.tabelNewVariant.rowDesignTable .ant-table-tbody > tr:nth-child(7) > td {
  background: #f0f0f0; }

.tabelNewVariant.rowDesignTable .ant-table-tbody > tr:nth-child(8) > td {
  background: #1a3665;
  color: #fff; }

.tableSearch,
.dateTableFilter {
  padding: 8px; }
  .tableSearch .ant-btn,
  .dateTableFilter .ant-btn {
    height: auto;
    min-height: 30px; }
  .tableSearch .ant-input,
  .dateTableFilter .ant-input {
    border: 1px solid #ccc;
    height: 30px;
    width: 100% !important; }
  .tableSearch .ant-btn,
  .dateTableFilter .ant-btn {
    background: transparent;
    color: #8c8c8c;
    border-color: #8c8c8c; }
  .tableSearch .ant-btn-primary,
  .dateTableFilter .ant-btn-primary {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff; }

.ant-table-filter-dropdown-btns > a {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.ant-table-filter-dropdown-btns .confirm {
  background: #1890ff;
  margin-right: 5px;
  color: #fff; }

.ant-table-filter-dropdown-btns .clear {
  font-weight: 400;
  border: 1px solid #d9d9d9; }

.AnalysisListTable.tableCustome table .ant-table-tbody .ant-table-row > td {
  line-height: 2.5;
  padding: 16px; }

.actionDropDown .ant-dropdown-menu-item > a {
  width: 140px; }

.actionDropDown .icon {
  height: 20px;
  width: 20px;
  margin-right: 5px; }

.marginLeft10 {
  margin-left: 10px; }

.react-resizable {
  position: relative;
  background-clip: padding-box; }

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1; }

.settingPopContent {
  background: #f1f1f1; }
  .settingPopContent .ant-collapse {
    background: transparent; }
    .settingPopContent .ant-collapse .ant-collapse-item {
      margin-bottom: 40px;
      border: 0;
      background: #ffffff; }
      .settingPopContent .ant-collapse .ant-collapse-item .ant-collapse-content-box {
        padding: 15px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px; }
  .settingPopContent .FPComponents .col-sm-12 > label:not(.ant-radio-wrapper) {
    width: 80px; }
  .settingPopContent .ant-collapse-arrow {
    transform: rotate(90deg) !important;
    top: 40% !important; }
  .settingPopContent .ant-collapse-item-active .ant-collapse-arrow {
    transform: rotate(180deg) !important; }
  .settingPopContent .ant-table-thead span.ant-table-column-title {
    color: #1a3665;
    font-size: 0.9em; }
  .settingPopContent .ant-tabs-left-content {
    background-color: white;
    height: 100%;
    padding-top: 24px; }
  .settingPopContent .ant-tabs-tab {
    padding: 8px 10px 8px 0px !important; }

.popuOverflowHeight {
  max-height: 650px !important;
  overflow: auto; }

.miniPopup {
  max-width: 500px;
  margin: 0 auto; }

.reportsPopContent .col-sm-5 {
  max-height: 500px;
  overflow: auto; }

.reportsPopContent label.ant-checkbox-wrapper {
  display: block;
  font-weight: 400;
  padding: 5px 0;
  font-size: 0.9rem;
  font-weight: 300; }

.reportsPopContent .selectedOptions {
  border-style: dashed !important;
  height: 300px;
  overflow: auto; }
  .reportsPopContent .selectedOptions .ant-checkbox-group {
    vertical-align: top;
    display: block; }

.reportsPopContent .ant-input {
  min-height: 340px; }

.dashboardTopBarGraphs {
  position: relative; }
  .dashboardTopBarGraphs .ant-collapse {
    width: 100%; }
    .dashboardTopBarGraphs .ant-collapse .ant-collapse-header {
      padding: 0; }
    .dashboardTopBarGraphs .ant-collapse .ant-collapse-item {
      min-height: 50px;
      border: 0; }
      .dashboardTopBarGraphs .ant-collapse .ant-collapse-item.ant-collapse-item-active .headerAccordion {
        opacity: 0;
        -webkit-transition: all ease-in-out 0.1s;
        -moz-transition: all ease-in-out 0.1s;
        -o-transition: all ease-in-out 0.1s;
        transition: all ease-in-out 0.1s; }
  .dashboardTopBarGraphs .headerAccordion {
    overflow: hidden;
    width: 100%;
    padding: 5px 15px;
    background: #fff;
    position: absolute;
    min-height: 50px; }
    .dashboardTopBarGraphs .headerAccordion > div {
      -webkit-transition: all ease-in-out 0.1s;
      -moz-transition: all ease-in-out 0.1s;
      -o-transition: all ease-in-out 0.1s;
      transition: all ease-in-out 0.1s;
      flex: 1;
      text-align: center; }
  .dashboardTopBarGraphs .ant-collapse-content-active {
    position: relative; }
    .dashboardTopBarGraphs .ant-collapse-content-active .ant-collapse-content-box {
      padding: 0 !important; }
      .dashboardTopBarGraphs .ant-collapse-content-active .ant-collapse-content-box .col-sm-4 {
        background: #fff; }
  .dashboardTopBarGraphs .ant-collapse-content-inactive {
    display: block !important; }
    .dashboardTopBarGraphs .ant-collapse-content-inactive .ant-collapse-content-box {
      padding: 20px !important; }
      .dashboardTopBarGraphs .ant-collapse-content-inactive .ant-collapse-content-box > div {
        display: none !important; }
  .dashboardTopBarGraphs .ant-collapse-arrow {
    padding: 10px 20px;
    top: 10px !important;
    right: 0 !important;
    z-index: 2;
    transform: rotate(90deg) !important; }
  .dashboardTopBarGraphs .ant-collapse-item-active .ant-collapse-arrow {
    transform: rotate(180deg) !important; }

.EditProflePic .ant-upload {
  float: none;
  margin: 0 auto;
  background: #fff;
  border-radius: 50%;
  height: 150px;
  width: 150px;
  overflow: hidden;
  display: flex !important;
  padding: 0 !important;
  align-items: center;
  justify-content: center; }

.modelPopAnt .ant-modal-content {
  background: #f3f7fa; }

.hideColumn {
  display: none; }

.reasonNonFunctional {
  color: #3a68c9; }

.reasonCompound {
  color: #22bda0; }

.reasonAmbiguos {
  color: #f4762b; }

.reasonOther {
  color: #54beff; }

.infoDescrtiption {
  font-size: 12px;
  line-height: 1.5; }

.columnDuplicates svg.icon {
  vertical-align: text-top;
  margin-left: 5px;
  height: 18px; }
  @media (max-width: 1400px) {
    .columnDuplicates svg.icon {
      height: 14px; } }

.columnDuplicates span.exactRquIcon {
  font-weight: 700;
  color: #f0b804;
  margin: 0 5px; }

.columnDuplicates span.similarRquIcon {
  font-weight: 700;
  color: #7b53ff;
  margin: 0 5px; }

.columnDuplicates span.contractoryRquIcon {
  font-weight: 700;
  color: #193663;
  margin: 0 5px; }

.ant-modal-body {
  background: #fff;
  overflow: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; }

.ant-collapse-borderless {
  background: #fff; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #018466; }

.ant-checkbox-checked .ant-checkbox-inner {
  background: #018466;
  border-color: #018466; }

.ant-message {
  top: auto;
  bottom: 20px;
  max-height: 190px; }

.ant-message-notice-content {
  background: transparent;
  padding: 0; }
  .ant-message-notice-content .ant-message-custom-content {
    background: #fff;
    padding: 10px 20px;
    min-width: 500px;
    text-align: left;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    line-height: 2;
    font-size: 16px; }
  .ant-message-notice-content .ant-message-warning {
    background: #fff3cd;
    color: #a1924f; }
  .ant-message-notice-content .ant-message-error {
    background: #f8d7da;
    color: #9c2c2d; }
  .ant-message-notice-content .ant-message-success {
    background: #d4edda;
    color: #457352; }

.ant-table-empty .ant-table-body {
  padding: 0 5px; }

.transitionType {
  color: #f4762b;
  font-weight: 700; }

.dataType {
  color: #1a3665; }

.tableID {
  font-weight: 700;
  color: #3a68c9; }

.ant-radio-checked .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #018466; }
  .ant-radio-checked .ant-radio-inner::after,
  .ant-radio-wrapper:hover .ant-radio::after,
  .ant-radio:hover .ant-radio-inner::after,
  .ant-radio-input:focus + .ant-radio-inner::after {
    background-color: #018466; }

.tabBtn {
  width: 75px;
  outline: none;
  border-radius: 0; }
  .tabBtn.tabBtnActive {
    background: #1a3665 !important;
    box-shadow: none; }

.totalReqInfo {
  min-height: 21px; }

.customeTabs .activeTab {
  background: #f3f7fa; }
  .customeTabs .activeTab td {
    background-color: #88d1f1 !important; }

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #88d1f1; }

.selectedRow > td {
  background: #88d1f1 !important; }

.customSpin {
  position: relative !important; }

.ant-tag {
  color: #7085d2;
  background: #ebebeb;
  font-weight: 700;
  border-radius: 15px;
  border: 0;
  margin-bottom: 15px;
  height: 21px; }
  .ant-tag .anticon-plus {
    margin-right: 5px; }

.tabsFunctionDiscovery > .ant-btn {
  border-bottom: 2px solid transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0; }
  .tabsFunctionDiscovery > .ant-btn.isTabActive {
    border-bottom-color: #f4762b; }

.funOptions .ant-tag {
  background: #ffece0 !important;
  color: #f4762b; }

.funOptions h6 {
  border-bottom: 1px dashed #f4762b;
  padding: 0 5px; }

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #f5f5f5; }

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #018466; }

.functionAccordian .ant-radio-wrapper {
  margin: 0 0 0 20px; }
  .functionAccordian .ant-radio-wrapper > span {
    font-size: 16px;
    font-weight: 600;
    color: #1a3665; }

.functionAccordian > ul li > label {
  margin: 0; }

.requirementNextPreview .ant-btn:not([disabled]) {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.32); }

.requrementPopTabsWrap .ant-tabs-nav {
  width: 100%; }
  .requrementPopTabsWrap .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #f4762b; }
  .requrementPopTabsWrap .ant-tabs-nav > div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    .requrementPopTabsWrap .ant-tabs-nav > div > .ant-tabs-tab {
      flex: 1;
      text-align: center; }

.requrementPopTabsWrap .ant-tabs-tabpane {
  height: 500px;
  overflow-y: auto; }

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background: #018466; }

.has-error ~ label.color-red {
  flex: 100%; }

.g2-tooltip-title {
  display: none; }

.requrmentGrade {
  width: 170px;
  margin-left: 55%;
  font-size: 12px; }
  @media (min-width: 1550px) {
    .requrmentGrade {
      margin-left: 72%; } }
  @media (max-width: 1350px) {
    .requrmentGrade {
      width: 125px;
      margin-left: 68%;
      font-size: 9px; } }

.ant-table-body .ant-checkbox-inner:after {
  display: inline-block !important; }

.eclipsText span {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis; }
  @media (max-width: 1400px) {
    .eclipsText span {
      max-width: 260px; } }

.eclipsText-100 {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.eclipsText-150 {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.eclipsText-180 {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.eclipsText-250 {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.flex-1 {
  flex: 1; }

.objectActionSidebar ul.list-inline {
  max-height: 250px;
  overflow: auto; }

.contentRender {
  padding-left: 130px; }
  .contentRender > .ant-spin {
    position: absolute;
    top: 0;
    z-index: 8; }
  @media (max-width: 1400px) {
    .contentRender {
      padding-left: 115px;
      min-height: 105vh; } }

.ant-table-row-expand-icon {
  border: 0;
  background: 0; }

.mediunIconSVG svg.icon {
  height: 32px;
  width: 32px; }
  @media (max-width: 1400px) {
    .mediunIconSVG svg.icon {
      font-size: 22px; } }

.progressBarWrapper .ant-steps-item {
  margin-right: 0 !important; }
  .progressBarWrapper .ant-steps-item .ant-steps-item-icon {
    margin: 0; }
    .progressBarWrapper .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border: 3px solid #fff;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1a3665; }
  .progressBarWrapper .ant-steps-item .ant-steps-item-title::after {
    height: 8px;
    left: 1px;
    top: 19px; }
    @media (max-width: 1400px) {
      .progressBarWrapper .ant-steps-item .ant-steps-item-title::after {
        height: 5px;
        top: 15px; } }
  .progressBarWrapper .ant-steps-item.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    background: #6fa85a; }
  .progressBarWrapper .ant-steps-item.ant-steps-item-finish .ant-steps-item-title::after {
    background: #6fa85a; }

.ant-steps-horizontal .ant-steps-item-icon {
  line-height: 25px !important; }

.ant-steps-horizontal label {
  cursor: pointer;
  text-decoration: underline; }

.ObjectActionCollapse .ant-collapse {
  background: transparent;
  border: 0; }
  .ObjectActionCollapse .ant-collapse .ant-collapse-item {
    margin-bottom: 5px; }
    .ObjectActionCollapse .ant-collapse .ant-collapse-item .ant-collapse-header {
      background: #fff;
      font-size: 16px;
      font-weight: 600;
      color: #1a3665; }
      .ObjectActionCollapse .ant-collapse .ant-collapse-item .ant-collapse-header:not(.ant-collapse-item-active) {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px; }
    .ObjectActionCollapse .ant-collapse .ant-collapse-item .ant-collapse-content {
      border-radius: 0 0 10px 10px;
      -webkit-border-radius: 0 0 10px 10px;
      -moz-border-radius: 0 0 10px 10px; }
      .ObjectActionCollapse .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
        padding: 0; }
        .ObjectActionCollapse .ant-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box > .row {
          padding: 15px;
          border-bottom: 1px solid #f1f1f1; }

.chartAlternetTitle .g2-tooltip {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-right: 40px !important; }
  .chartAlternetTitle .g2-tooltip .g2-tooltip-title {
    display: inline;
    margin-right: 10px; }
  .chartAlternetTitle .g2-tooltip .g2-tooltip-list {
    font-size: 0;
    position: absolute;
    right: 10px;
    top: 10px; }
    .chartAlternetTitle .g2-tooltip .g2-tooltip-list svg {
      visibility: hidden;
      display: none !important; }
    .chartAlternetTitle .g2-tooltip .g2-tooltip-list .g2-tooltip-value {
      font-size: 14px; }

.ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
  max-height: 160px; }

.ObjectActionCollapse .ant-collapse {
  max-height: 240px;
  overflow: auto; }

.chartScrollbar {
  max-width: 100%;
  overflow: auto; }

.analysisChart svg {
  width: 100%;
  max-width: 700px; }

#result_metrics_svgchart svg {
  height: 400px; }

.maxHeight-500 {
  max-height: 500px !important;
  overflow: auto; }

.maxHeight-300 {
  max-height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden; }

.maxHeight-200 {
  max-height: 200px !important;
  overflow: auto; }

.minHeight-500 {
  max-height: 500px !important;
  min-height: 500px !important;
  overflow: auto; }
  @media (max-width: 1260px) {
    .minHeight-500 {
      max-height: 380px !important;
      min-height: 380px !important; } }

.minHeight-400 {
  max-height: 400px !important;
  min-height: 400px !important;
  overflow: auto; }

.minHeight-300 {
  max-height: 300px !important;
  min-height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden; }

.minHeight-200 {
  max-height: 200px !important;
  min-height: 200px !important;
  overflow: auto; }

.multiplierToggleBtn .tabBtn:not(.tabBtnActive) {
  background: #a9a9a9 !important; }

#multiplier + label.color-red.d-block {
  position: absolute; }

.avatar-uploader {
  overflow: hidden; }

.largeToolTip {
  max-width: 350px; }

span.redDot {
  display: inline-block;
  border: 4px solid red;
  border-radius: 50%; }

.searchFilter .ant-select-selection {
  padding-right: 30px; }

.searchFilter .ant-select-selection__clear {
  right: 37px; }

.searchFilter input.ant-select-search__field {
  width: 100% !important;
  padding: 3px !important; }
  .searchFilter input.ant-select-search__field:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none; }

.filterBtnSearch {
  right: 0;
  top: 7px;
  background: none;
  border: 0;
  padding: 0; }

.MajorFunctionalityChartWrap span.g2-tooltip-value:after {
  content: "%"; }

.settingPopContent .ant-collapse {
  max-height: 480px;
  min-height: 480px;
  overflow-y: auto; }

.ant-spin-dot-item {
  background-color: #193663; }

.ant-spin-text {
  color: #193663; }

/* IE10+ specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .projectStatus .analysisChart svg {
    height: 230px; }
  .container-fluid.fullHeight {
    min-height: 100%; }
  .sidebar-wrapper {
    min-height: 100%; }
  .sidebar .toggleMenu {
    right: -10px; }
  .fullHeight .ant-affix {
    height: auto !important;
    position: relative !important; }
  .inputQuery .col.d-inline-flex {
    overflow-y: hidden;
    overflow-x: auto; }
    .inputQuery .col.d-inline-flex > .text-center {
      width: 75px;
      display: inline-table; }
  .sidebar {
    width: 100%; }
    .sidebar.sidebarInvisible {
      width: 105px; } }

.helpDrawer li.border-bottom > a {
  transition: all 0.3s; }
  .helpDrawer li.border-bottom > a:hover {
    padding-left: 20px !important;
    background: #f4762b;
    color: #fff; }

span.collpase-text {
  font-weight: 500;
  font-family: "Montserrat", sans-serif; }

.topProgressBarContent {
  height: 120px;
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s; }
  .topProgressBarContent.topProgressBarContentNone {
    height: 0px;
    visibility: hidden;
    overflow: hidden;
    opacity: 0; }
  .topProgressBarContent .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox:before {
    content: "";
    height: 25px;
    width: 6px;
    background: #ccc;
    transform: revert;
    border: 0;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 12px; }
  .topProgressBarContent .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox .ant-checkbox-inner {
    background: transparent url("../img/gray-tick.svg") no-repeat top center;
    background-size: contain;
    border: 0; }

.orangeClassForCheck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: orange;
  border-color: orange; }

.greenClassForCheck .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green;
  border-color: green; }

.ant-steps-item-icon > div:last-child .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox:before {
  display: none; }

.greenClassForCheck.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox:before {
  background: #6fa85a; }

.orangeClassForCheck.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox:before {
  background: #f4762b; }

.topProgressBarContent .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  display: none; }

.greenClassForCheck.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox .ant-checkbox-inner {
  background: transparent url("../img/green-tick.svg") no-repeat top center;
  background-size: contain;
  border: 0; }

.orangeClassForCheck.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox .ant-checkbox-inner {
  background: transparent url("../img/orange-tick.svg") no-repeat top center;
  background-size: contain;
  border: 0; }

.manualEntry_Table .ant-table-body {
  max-height: 610px;
  overflow-y: auto;
  overflow-x: hidden; }

.ant-select-selection {
  padding: 0px; }

@-moz-document url-prefix() {
  .equivalentSizeTable {
    width: 90%;
    margin-left: auto;
    margin-right: auto; } }

.uploadFileTableColumn {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.analysisSheetData select {
  min-width: 120px; }

.analysisSheetData .intro {
  margin-top: 10px;
  border-top: 1px solid;
  border-color: #b9b9b9; }

.newAnalysisSheetData .ant-table-body {
  max-height: calc(100vh - 300px);
  overflow: auto; }

.item {
  font-size: 15px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
  background-color: lightgray; }

.color-bar {
  width: 40px;
  height: 10px;
  border-radius: 5px; }

.item-title {
  font-weight: 600;
  font-size: 16px; }

.item-status {
  text-align: right; }

.highlight-region {
  background-color: yellow; }

.col-wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  background-color: red;
  border-radius: 5px;
  width: 23%; }

.col-header {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0; }

.drop-wrapper {
  flex: 1 25%;
  width: 100%;
  height: 100%; }

.add-three-dots {
  display: inline-block;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #fffcd7;
  padding: 5px; }

.requiremntsDragDrop {
  width: 20%;
  margin: 0 15px; }
  .requiremntsDragDrop div[data-rbd-droppable-id] {
    background-color: #f3f7fa !important; }
  .requiremntsDragDrop div[data-rbd-draggable-id] {
    background-color: white !important;
    color: black !important; }
  .requiremntsDragDrop h5 {
    color: black !important;
    text-align: center;
    padding: 5px;
    font-weight: bold; }
  .requiremntsDragDrop .noStartedReqHeader {
    background-color: #ffff00 !important; }
  .requiremntsDragDrop .analysisReqHeader {
    background-color: #f79646 !important; }
  .requiremntsDragDrop .buildReqHeader {
    background-color: #00b0f0 !important; }
  .requiremntsDragDrop .integrateReqHeader {
    background-color: #a6a6a6 !important; }
  .requiremntsDragDrop .doneReqHeader {
    background-color: #92d050 !important; }

.serarchButtonStyle {
  background-color: orange;
  border: 0;
  height: 35px; }

.letterSpacing1 {
  letter-spacing: 1px; }

.customeSettingTabs .tabList {
  cursor: pointer;
  border-right: 2px solid transparent; }
  .customeSettingTabs .tabList.activeSettingTab {
    color: #1890ff;
    border-color: #1890ff; }
  .customeSettingTabs .tabList.customeError {
    color: red;
    border-color: red; }

.customeSettingTabs .custimeSettingTabContent {
  background: #ffffff;
  height: 100%;
  flex: 2;
  min-height: 500px; }
  .customeSettingTabs .custimeSettingTabContent .settingTabContent label {
    font-size: 13px; }

.actionDelete {
  font-size: 12px;
  position: absolute;
  height: 15px;
  width: 15px;
  right: -7px;
  top: -7px;
  background-color: red;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer; }
  .actionDelete::after {
    content: "X"; }

.objectDelete {
  font-size: 12px;
  position: absolute;
  height: 15px;
  width: 15px;
  right: -7px;
  top: -7px;
  background-color: red;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer; }
  .objectDelete::after {
    content: "X"; }

.otherActionDelete {
  font-size: 12px;
  position: absolute;
  height: 15px;
  width: 15px;
  right: -7px;
  top: -7px;
  background-color: red;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer; }
  .otherActionDelete::after {
    content: "X"; }

.fpReviewSpan {
  padding: 0;
  border: 2px solid red;
  font-size: 12px;
  position: absolute;
  height: 24px;
  width: 24px;
  top: -15px;
  background-color: white;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center; }

.fpReviewRightBorder {
  border-right: 2px solid lightgray; }

.custom-tooltip {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.96);
  color: #646464;
  width: 150px;
  height: auto;
  border-radius: 3px;
  font-size: 13px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px #aeaeae;
  padding: 10px; }

.custom-tooltip-dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px; }

.reportsRadioButton .ant-radio-wrapper {
  width: 47%; }

.ant-affix .sidebar-wrapper {
  height: 100vh; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .topProgressBarContent .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox .ant-checkbox-inner {
    background-position: 0 0;
    background-size: 93%; }
  .orangeClassForCheck.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox .ant-checkbox-inner {
    background-position: 0 0;
    background-size: 120%; }
  .greenClassForCheck.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled > .ant-checkbox .ant-checkbox-inner {
    background-position: 0 0;
    background-size: 120%; }
  .equivalentSizeTableIE > table input {
    max-width: 130px; } }

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; }

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1; }

.tooltip:hover .tooltiptext {
  visibility: visible; }

.mr-center {
  margin: 0px auto; }

.jiraModl .ant-select-selection__rendered {
  line-height: 38px; }

.jiraModl .ant-select-focused .ant-select-selection,
.jiraModl .ant-select-selection:hover,
.jiraModl .ant-select-selection:focus,
.jiraModl .ant-select-selection:active {
  border-color: #8a8a8a;
  box-shadow: none; }

.versionDDL .ant-select-selection__rendered {
  line-height: 36px !important; }

.modified-search .ant-table-thead > tr > th .ant-table-filter-icon {
  position: relative !important;
  background-color: transparent !important; }
  .modified-search .ant-table-thead > tr > th .ant-table-filter-icon > svg {
    margin-top: 10px !important;
    margin-left: 0 !important;
    position: relative !important; }

.roadmapContainer {
  display: flex; }
  .roadmapContainer .left-headings {
    flex: 1;
    padding-bottom: 25px; }
    .roadmapContainer .left-headings .modifiedIcon {
      height: auto !important;
      width: 40px !important; }
    .roadmapContainer .left-headings .tableHeadDND {
      padding: 24px;
      width: 100%;
      height: 146px;
      background-color: #1890ff;
      margin: 14.5px 0px;
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;
      font-size: 20px; }
  .roadmapContainer .rightDND {
    flex: 9;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 10px 10px; }
    .roadmapContainer .rightDND .dropableContainer {
      min-width: 350px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 10px 10px 0px;
      background-color: #e8e8e8;
      margin-right: 20px; }
      .roadmapContainer .rightDND .dropableContainer .sprintHead {
        width: 60%;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        height: 50px;
        color: white; }
      .roadmapContainer .rightDND .dropableContainer .DNDContainer {
        flex: 3;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0px 5px 0px 12px; }
        .roadmapContainer .rightDND .dropableContainer .DNDContainer .innerRequirementContainer {
          border-left: 11px solid;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          flex: 1 1 0%;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 9px;
          border-color: #1890ff;
          box-shadow: rgba(0, 0, 0, 0.15) -0.05px 1.95px 0px; }
          .roadmapContainer .rightDND .dropableContainer .DNDContainer .innerRequirementContainer .requirement-text {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden; }
        .roadmapContainer .rightDND .dropableContainer .DNDContainer .requirementBox {
          display: flex;
          align-items: center;
          margin-top: 10px;
          background-color: white;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px; }
      .roadmapContainer .rightDND .dropableContainer .sprintHead {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #9d9090; }
      .roadmapContainer .rightDND .dropableContainer .requirementContainer {
        height: 160px;
        border-bottom: 10px solid #e8e8e8;
        min-width: 325px;
        padding: 10px;
        overflow-y: auto;
        background: #f3f7fa !important; }
    .roadmapContainer .rightDND .sprintfoot {
      font-size: 16px;
      padding: 15px;
      border-left: 10px solid #e8e8e8;
      min-width: 325px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f3f7fa; }
  .roadmapContainer .sprint-1 {
    background-color: #00b0f0; }
  .roadmapContainer .sprint-2 {
    background-color: #fabcbc; }
  .roadmapContainer .sprint-3 {
    background-color: #f00; }
  .roadmapContainer .sprint-4 {
    background-color: #3ecc83; }

.modifiedSwitch .ant-switch-checked {
  background-color: #3ecc83 !important; }

.modifiedSwitch .ant-switch {
  background-color: red; }

.modifiedBulkAction .ant-select-selection__rendered {
  top: 4px; }

.newRoadmapIcon {
  border-radius: 50%;
  border-top-left-radius: 50%; }

.is-not-under-budget-color :nth-child(4) {
  background-color: rgba(255, 0, 0, 0.413) !important; }

.is-under-budget-color :nth-child(4) {
  background-color: rgba(154, 239, 171, 0.413); }

.sizereduced {
  font-size: 0.85rem; }
